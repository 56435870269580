exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bundle-offer-js": () => import("./../../../src/pages/bundle-offer.js" /* webpackChunkName: "component---src-pages-bundle-offer-js" */),
  "component---src-pages-bundle-save-js": () => import("./../../../src/pages/bundle-save.js" /* webpackChunkName: "component---src-pages-bundle-save-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-corporate-gifting-js": () => import("./../../../src/pages/corporate-gifting.js" /* webpackChunkName: "component---src-pages-corporate-gifting-js" */),
  "component---src-pages-daybreak-js": () => import("./../../../src/pages/daybreak.js" /* webpackChunkName: "component---src-pages-daybreak-js" */),
  "component---src-pages-dreamscapes-js": () => import("./../../../src/pages/dreamscapes.js" /* webpackChunkName: "component---src-pages-dreamscapes-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-fathers-day-2022-js": () => import("./../../../src/pages/fathers-day-2022.js" /* webpackChunkName: "component---src-pages-fathers-day-2022-js" */),
  "component---src-pages-fathers-day-tdf-js": () => import("./../../../src/pages/fathers-day-tdf.js" /* webpackChunkName: "component---src-pages-fathers-day-tdf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-july-luggage-review-js": () => import("./../../../src/pages/july-luggage-review.js" /* webpackChunkName: "component---src-pages-july-luggage-review-js" */),
  "component---src-pages-luggage-js": () => import("./../../../src/pages/luggage.js" /* webpackChunkName: "component---src-pages-luggage-js" */),
  "component---src-pages-magenta-moment-js": () => import("./../../../src/pages/magenta-moment.js" /* webpackChunkName: "component---src-pages-magenta-moment-js" */),
  "component---src-pages-our-store-js": () => import("./../../../src/pages/our-store.js" /* webpackChunkName: "component---src-pages-our-store-js" */),
  "component---src-pages-personalisation-js": () => import("./../../../src/pages/personalisation.js" /* webpackChunkName: "component---src-pages-personalisation-js" */),
  "component---src-pages-red-black-trunk-luggage-js": () => import("./../../../src/pages/red-black-trunk-luggage.js" /* webpackChunkName: "component---src-pages-red-black-trunk-luggage-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-sun-sea-js": () => import("./../../../src/pages/sun-sea.js" /* webpackChunkName: "component---src-pages-sun-sea-js" */),
  "component---src-pages-the-details-js": () => import("./../../../src/pages/the-details.js" /* webpackChunkName: "component---src-pages-the-details-js" */),
  "component---src-pages-the-ejectable-battery-js": () => import("./../../../src/pages/the-ejectable-battery.js" /* webpackChunkName: "component---src-pages-the-ejectable-battery-js" */),
  "component---src-pages-travel-bags-js": () => import("./../../../src/pages/travel-bags.js" /* webpackChunkName: "component---src-pages-travel-bags-js" */),
  "component---src-pages-trunk-js": () => import("./../../../src/pages/trunk.js" /* webpackChunkName: "component---src-pages-trunk-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/Blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-memorable-holiday-trip-js": () => import("./../../../src/templates/Blog/memorable-holiday-trip.js" /* webpackChunkName: "component---src-templates-blog-memorable-holiday-trip-js" */),
  "component---src-templates-bottle-700-js": () => import("./../../../src/templates/Bottle/700.js" /* webpackChunkName: "component---src-templates-bottle-700-js" */),
  "component---src-templates-bottle-index-js": () => import("./../../../src/templates/Bottle/index.js" /* webpackChunkName: "component---src-templates-bottle-index-js" */),
  "component---src-templates-bundles-carry-checked-js": () => import("./../../../src/templates/Bundles/CarryChecked.js" /* webpackChunkName: "component---src-templates-bundles-carry-checked-js" */),
  "component---src-templates-bundles-carry-plus-js": () => import("./../../../src/templates/Bundles/CarryPlus.js" /* webpackChunkName: "component---src-templates-bundles-carry-plus-js" */),
  "component---src-templates-bundles-family-js": () => import("./../../../src/templates/Bundles/Family.js" /* webpackChunkName: "component---src-templates-bundles-family-js" */),
  "component---src-templates-bundles-trunk-js": () => import("./../../../src/templates/Bundles/Trunk.js" /* webpackChunkName: "component---src-templates-bundles-trunk-js" */),
  "component---src-templates-carry-all-backpack-js": () => import("./../../../src/templates/CarryAll/backpack.js" /* webpackChunkName: "component---src-templates-carry-all-backpack-js" */),
  "component---src-templates-carry-all-daypack-js": () => import("./../../../src/templates/CarryAll/daypack.js" /* webpackChunkName: "component---src-templates-carry-all-daypack-js" */),
  "component---src-templates-carry-all-landing-js": () => import("./../../../src/templates/CarryAll/landing.js" /* webpackChunkName: "component---src-templates-carry-all-landing-js" */),
  "component---src-templates-carry-all-pocket-js": () => import("./../../../src/templates/CarryAll/pocket.js" /* webpackChunkName: "component---src-templates-carry-all-pocket-js" */),
  "component---src-templates-carry-all-sling-js": () => import("./../../../src/templates/CarryAll/sling.js" /* webpackChunkName: "component---src-templates-carry-all-sling-js" */),
  "component---src-templates-carry-all-tote-js": () => import("./../../../src/templates/CarryAll/tote.js" /* webpackChunkName: "component---src-templates-carry-all-tote-js" */),
  "component---src-templates-carry-all-weekender-js": () => import("./../../../src/templates/CarryAll/weekender.js" /* webpackChunkName: "component---src-templates-carry-all-weekender-js" */),
  "component---src-templates-carry-all-weekender-plus-js": () => import("./../../../src/templates/CarryAll/weekender-plus.js" /* webpackChunkName: "component---src-templates-carry-all-weekender-plus-js" */),
  "component---src-templates-carry-on-new-js": () => import("./../../../src/templates/CarryOn/new.js" /* webpackChunkName: "component---src-templates-carry-on-new-js" */),
  "component---src-templates-carry-on-pro-new-js": () => import("./../../../src/templates/CarryOnPro/new.js" /* webpackChunkName: "component---src-templates-carry-on-pro-new-js" */),
  "component---src-templates-checked-new-js": () => import("./../../../src/templates/Checked/new.js" /* webpackChunkName: "component---src-templates-checked-new-js" */),
  "component---src-templates-checked-plus-new-js": () => import("./../../../src/templates/CheckedPlus/new.js" /* webpackChunkName: "component---src-templates-checked-plus-new-js" */),
  "component---src-templates-covers-trunk-js": () => import("./../../../src/templates/Covers/trunk.js" /* webpackChunkName: "component---src-templates-covers-trunk-js" */),
  "component---src-templates-daybreak-backpack-js": () => import("./../../../src/templates/Daybreak/backpack.js" /* webpackChunkName: "component---src-templates-daybreak-backpack-js" */),
  "component---src-templates-daybreak-tote-js": () => import("./../../../src/templates/Daybreak/tote.js" /* webpackChunkName: "component---src-templates-daybreak-tote-js" */),
  "component---src-templates-daybreak-weekender-js": () => import("./../../../src/templates/Daybreak/weekender.js" /* webpackChunkName: "component---src-templates-daybreak-weekender-js" */),
  "component---src-templates-fathers-day-2021-js": () => import("./../../../src/templates/fathers-day2021.js" /* webpackChunkName: "component---src-templates-fathers-day-2021-js" */),
  "component---src-templates-gift-card-new-js": () => import("./../../../src/templates/GiftCard/new.js" /* webpackChunkName: "component---src-templates-gift-card-new-js" */),
  "component---src-templates-large-toiletry-bag-toiletry-bag-js": () => import("./../../../src/templates/LargeToiletryBag/toiletry-bag.js" /* webpackChunkName: "component---src-templates-large-toiletry-bag-toiletry-bag-js" */),
  "component---src-templates-light-index-js": () => import("./../../../src/templates/Light/index.js" /* webpackChunkName: "component---src-templates-light-index-js" */),
  "component---src-templates-luggage-tags-index-js": () => import("./../../../src/templates/LuggageTags/index.js" /* webpackChunkName: "component---src-templates-luggage-tags-index-js" */),
  "component---src-templates-me-new-js": () => import("./../../../src/templates/Me/new.js" /* webpackChunkName: "component---src-templates-me-new-js" */),
  "component---src-templates-me-strap-index-js": () => import("./../../../src/templates/MeStrap/index.js" /* webpackChunkName: "component---src-templates-me-strap-index-js" */),
  "component---src-templates-notebooks-moleskin-js": () => import("./../../../src/templates/Notebooks/Moleskin.js" /* webpackChunkName: "component---src-templates-notebooks-moleskin-js" */),
  "component---src-templates-packing-new-js": () => import("./../../../src/templates/Packing/new.js" /* webpackChunkName: "component---src-templates-packing-new-js" */),
  "component---src-templates-phone-cases-classic-js": () => import("./../../../src/templates/PhoneCases/classic.js" /* webpackChunkName: "component---src-templates-phone-cases-classic-js" */),
  "component---src-templates-phone-cases-max-js": () => import("./../../../src/templates/PhoneCases/max.js" /* webpackChunkName: "component---src-templates-phone-cases-max-js" */),
  "component---src-templates-phone-cases-mini-js": () => import("./../../../src/templates/PhoneCases/mini.js" /* webpackChunkName: "component---src-templates-phone-cases-mini-js" */),
  "component---src-templates-power-bank-index-js": () => import("./../../../src/templates/PowerBank/index.js" /* webpackChunkName: "component---src-templates-power-bank-index-js" */),
  "component---src-templates-static-page-index-js": () => import("./../../../src/templates/StaticPage/index.js" /* webpackChunkName: "component---src-templates-static-page-index-js" */),
  "component---src-templates-tech-kit-index-js": () => import("./../../../src/templates/TechKit/index.js" /* webpackChunkName: "component---src-templates-tech-kit-index-js" */),
  "component---src-templates-toiletry-bag-toiletry-bag-js": () => import("./../../../src/templates/ToiletryBag/toiletry-bag.js" /* webpackChunkName: "component---src-templates-toiletry-bag-toiletry-bag-js" */),
  "component---src-templates-trunk-carry-on-js": () => import("./../../../src/templates/Trunk/CarryOn.js" /* webpackChunkName: "component---src-templates-trunk-carry-on-js" */),
  "component---src-templates-trunk-checked-js": () => import("./../../../src/templates/Trunk/Checked.js" /* webpackChunkName: "component---src-templates-trunk-checked-js" */),
  "component---src-templates-win-baby-travel-bundle-js": () => import("./../../../src/templates/win-baby-travel-bundle.js" /* webpackChunkName: "component---src-templates-win-baby-travel-bundle-js" */)
}

