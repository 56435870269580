module.exports = {
  au: {
    path: 'au',
    locale: 'English',
    default: true,
    currency: 'AUD',
    store: 'july-travel',
    content: 'default',
  },
  us: {
    path: 'us',
    locale: 'English',
    currency: 'USD',
    store: 'july-us',
    content: 'us',
  },
  // nz: {
  //   path: 'nz',
  //   locale: 'English',
  //   currency: 'AUD',
  //   store: 'july-travel',
  //   content: 'nz'
  // },
  // uk: {
  //   path: 'uk',
  //   locale: 'English',
  //   currency: 'GBP',
  //   store: 'july-travel',
  //   content: 'uk'
  // },
  // global: {
  //   path: 'global',
  //   locale: 'English',
  //   currency: 'USD',
  //   store: 'july-us',
  //   content: 'global'
  // },
}
