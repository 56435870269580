const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  script.id = 'okendo_script'
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    if(window.location.href.includes('travel-bags') || window.location.href.includes('luggage') || window.location.href.includes('travel-accessories') || window.location.href.includes('reviews') || window.location.href.includes('july-luggage-review') || window.location.href.includes('corporate-gifting')) {
      
      if(window.location.href.includes('au')){
        addScript("/okendo.js")
      }else if (window.location.href.includes('us')) {
        addScript("/okendo-us.js")
      }

    }
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  let scriptTag = document.getElementById("okendo_script");
  if(scriptTag){
    document.body.removeChild( scriptTag );
  }
  if(location.pathname.includes('travel-bags') || location.pathname.includes('luggage') || location.pathname.includes('travel-accessories') || location.pathname.includes('reviews') || window.location.href.includes('corporate-gifting')) {
    if(window.location.href.includes('au')){
      addScript("/okendo.js")
    }else if (window.location.href.includes('us')) {
      addScript("/okendo-us.js")
    }
  }
}